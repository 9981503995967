import { Component } from "react";
import styled from "styled-components";
import { graphql } from "gatsby";

// Utils
import { Color, Font, rem, media, responsive } from "../../utils/style";
import PressArticles from "../../utils/press";

// Components
import PageSEO from "../../components/seo/Page";
import Container from "../../components/Container";
import Row from "../../components/Row";
import PageHero from "../../components/PageHero";
import Text from "../../components/Text";

// Styled Elements

const HeroContent = styled.div.attrs({
  className: "d-flex align-items-center justify-content-center",
})`
  height: 100%;

  h1 {
    font-size: ${rem(92)};
    line-height: 1em;
    color: ${Color.white};
    font-weight: normal;
    margin: 0;

    ${media.mobile`
      font-size: ${rem(42)};
    `};
  }
`;

const ContactBanner = styled.div`
  background-color: #f5d356;
  color: ${Color.white};
  padding: 20px 0;
  text-align: center;
  margin: 0 auto;

  width: calc(100vw - 16px);
  padding-left: 8px;
  padding-right: 8px;

  ${responsive.sm`
    width: calc(100vw - 48px);
    padding-left: 0px;
    padding-right: 0px;
  `}

  ${responsive.lg`
    width: calc(100vw - 80px);
    max-width: 1920px;
  `}

  p,
  a {
    margin: 0;
    color: inherit;
    padding: 0;
    font-size: ${rem(18)};
    line-height: ${rem(20)};

    ${media.mobile`
      font-size: ${rem(16)};
      line-height: ${rem(18)};
    `};
  }

  p {
    ${Font.circular};
  }

  a {
    ${Font.dutch};
    font-style: italic;

    &:hover {
      color: ${Color.white};
    }
  }
`;

const PressContainer = styled(Container)`
  padding-top: 30px;
  padding-bottom: 30px;
  max-width: 880px;

  ${media.mobile`
    padding-bottom: 0;
  `};
`;

const PressBlock = styled.div.attrs({
  className: "col-12 col-sm-6 col-md-4",
})`
  position: relative;
  box-sizing: border-box;
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: center;

  ${media.mobile`
    padding-top: 0;
    padding-bottom: 0;

    &:last-of-type {
      a > div {
        border-bottom: none;
      }
    }
  `};
`;

const PressBlockStyle = styled.div`
  border: 2px solid ${Color.ritualBlue};
  min-height: 190px;
  padding: 30px 20px;

  ${media.mobile`
    border: none;
    border-bottom: 2px solid ${Color.ritualBlue};
  `};

  p {
    ${Font.dutch};
    font-size: ${rem(14)};
    line-height: ${rem(18)};
    word-break: none;
    hyphens: manual;

    ${media.tablet`
      font-size: ${rem(16)};
      line-height: ${rem(20)};
    `};

    ${media.mobile`
      font-size: ${rem(16)};
      line-height: ${rem(20)};
    `};
  }

  span {
    display: block;
    position: relative;
    height: 35px;

    img {
      max-height: 35px;
      max-width: 120px;
    }
  }
`;

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.articles = PressArticles;
    this.state = {
      seo: {
        pagePath: "press",
        title: "In The Press - Ritual",
        description:
          "Committed to creating the purest, most effective formulations for everyday health, and sharing every ingredient, source & scientist behind our products.",
      },
    };
  }

  componentDidMount() {
    this.props.updatePageData({
      label: "Press",
    });
  }

  renderPressBlock(article, i) {
    let content = (
      <PressBlockStyle className="d-flex flex-column align-items-center justify-content-between">
        <p>{article.title}</p>
        <span>
          <img src={article.image} alt={article.title} />
        </span>
      </PressBlockStyle>
    );
    if (article.url) {
      content = (
        <a
          href={article.url}
          title={article.title}
          aria-label={article.title}
          hrefLang="x-default"
        >
          {content}
        </a>
      );
    }
    return <PressBlock key={i}>{content}</PressBlock>;
  }

  render() {
    const { data } = this.props;

    return (
      <>
        <PageSEO {...this.state.seo} />

        <PageHero
          backgroundColor={"#002773"}
          imageMobile={data.heroImageMobile}
          imageDesktop={data.heroImageDesktop}
        >
          <HeroContent>
            <h1>
              <Text id="press.heading" defaultMessage="In The Press" />
            </h1>
          </HeroContent>
        </PageHero>

        <ContactBanner>
          <p>
            <Text
              id="press.contact.banner"
              defaultMessage="Press & Media inquiries?"
            />
          </p>
          <a
            href="mailto:partnerships@ritual.com"
            title="Ritual Partnerships Email"
          >
            partnerships@ritual.com
          </a>
        </ContactBanner>

        <PressContainer>
          <Row>
            <div className="col-12 mb-3"></div>
          </Row>
          <Row>
            {this.articles.map((article, i) => {
              return this.renderPressBlock(article, i);
            })}
          </Row>
        </PressContainer>
      </>
    );
  }
}

export const pageQuery = graphql`
  query PressPage($locale: String!) {
    heroImageMobile: contentfulAsset(
      node_locale: { eq: $locale }
      contentful_id: { eq: "5HCKD5zyJ5QOmgOgmhawUO" }
    ) {
      description
      id
      gatsbyImageData(
        layout: CONSTRAINED
        placeholder: DOMINANT_COLOR
        width: 912
        quality: 80
      )
    }
    heroImageDesktop: contentfulAsset(
      node_locale: { eq: $locale }
      contentful_id: { eq: "39azGLr5eQXTdpipfKW6wj" }
    ) {
      description
      id
      gatsbyImageData(
        layout: CONSTRAINED
        placeholder: DOMINANT_COLOR
        width: 1920
        quality: 80
      )
    }
  }
`;

import { createRef, Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Hls from "../services/hls";
import { graphql } from "gatsby";

// Styled Elements
const MagicVideoWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  video {
    max-width: 100%;
    max-height: 100%;

    [data-whatintent="mouse"] &:focus,
    [data-whatintent="touch"] &:focus {
      outline: none;
    }
  }

  ${(p) => p.videoStyle};
`;

// Video related
const langLabel = {
  en: "English",
};

export default class MagicVideo extends Component {
  static propTypes = {
    captions: PropTypes.array,
    className: PropTypes.string,
    sources: PropTypes.array,
    videoStyle: PropTypes.any,
  };

  constructor(props) {
    super(props);

    this.videoElement = createRef();
    this.locale = null;
  }

  componentDidMount() {
    this.locale = window.navigator.language;

    this.mountStream();
  }

  mountStream() {
    const { stream } = this.props;
    if (stream && stream.videoUpload) {
      const url = this.getStreamUrl(stream);
      Hls.activate(url, this.videoElement.current, {
        title: this.props.title,
      });
    }
  }

  play() {
    this.videoElement.current.play();
  }

  pause() {
    this.videoElement.current.pause();
  }

  getPosterUrl() {
    const { poster, stream } = this.props;

    if (poster && poster.file) return poster.file.url;
    if (stream && stream.videoUpload) {
      const id = stream.videoUpload.playbackId;
      return `https://image.mux.com/${id}/thumbnail.jpg?time=0`;
    }
  }

  getStreamUrl(stream) {
    return `https://stream.mux.com/${stream.videoUpload.playbackId}.m3u8`;
  }

  renderCaption(caption, kind = "captions") {
    let { description: locale, file } = caption;
    if (!locale) locale = "en-us";

    return (
      <track
        key={file.url}
        kind={kind}
        src={file.url}
        srcLang={locale}
        label={langLabel[locale]}
        default={locale === this.locale}
      />
    );
  }

  render() {
    const {
      /* Attributes */
      autoPlay = false,
      captions,
      descriptionFile,
      controls = true,
      crossorigin,
      loop = false,
      preload = "metadata",
      sources,
      muted = false,
      playsInline = false,
      stream,
      /* Interaction Callbacks */
      onPause,
      onEnded,
      onPlay,
      onSeeking,
      onSeeked,
      onClick,
      /* Other */
      className,
      videoStyle = {},
      videoElementStyle = {},
    } = this.props;

    if (!sources && !stream) {
      console.warn("No video sources found");
      return null;
    }

    if (!captions) console.warn("Captions missing for video");

    let videoProps = {
      /* Attributes */
      poster: this.getPosterUrl(),
      autoPlay,
      controls,
      loop,
      preload,
      muted,
      playsInline,
      stream,
      /* Interaction Callbacks */
      onPause,
      onEnded,
      onPlay,
      onSeeking,
      onSeeked,
      onClick,
    };

    if (crossorigin) {
      videoProps.crossOrigin = crossorigin;
    }

    /* eslint-disable jsx-a11y/media-has-caption */
    /* ^ jsx-a11y cant see <track> inside the .map ¯\_(ツ)_/¯ */
    return (
      <MagicVideoWrapper className={className} videoStyle={videoStyle}>
        <video
          /* Props */
          {...videoProps}
          /* Other */
          ref={this.videoElement}
          style={videoElementStyle}
        >
          {sources &&
            sources.map((source, i) => {
              const { file, id } = source;
              return (
                <source
                  id={id}
                  key={i}
                  src={file.url}
                  type={file.contentType}
                />
              );
            })}
          {descriptionFile &&
            descriptionFile.map((source) =>
              this.renderCaption(source, "description"),
            )}
          {captions && captions.map((caption) => this.renderCaption(caption))}
        </video>
      </MagicVideoWrapper>
    );
    /* eslint-enable */
  }
}

// GraphQL Fragment
export const videoContent = graphql`
  fragment videoContent on ContentfulVideoContent {
    ... on ContentfulVideoContent {
      title
      description {
        description
      }
      descriptionFile {
        file {
          url
        }
        description
      }
      videoLength
      sources {
        id
        file {
          url
          contentType
        }
        description
      }
      captions {
        file {
          url
        }
        description
      }
      stream {
        videoUpload {
          playbackId
        }
      }
    }
  }
`;
